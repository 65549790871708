/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DeliveryAddress } from "@whitelabel-webapp/address/delivery-address";
import { AddressProvider } from "@whitelabel-webapp/address/shared/address-store";
import { ClosestMerchant } from "@whitelabel-webapp/chain/closest-merchant";
import { ChainProvider } from "@whitelabel-webapp/chain/shared/chain-store";
import { Chain, ChainResponse } from "@whitelabel-webapp/chain/shared/models";
import { Page } from "@whitelabel-webapp/shared/page-wrapper";
import { SsrLogger } from "@whitelabel-webapp/shared/ssr-utils";
import { AxiosError } from "axios";
import { GetServerSideProps } from "next";

type ChainPageProps = {
  chainResponse: ChainResponse;
};

const ChainPage: React.VFC<ChainPageProps> = ({ chainResponse }) => {
  return (
    <Page
      name="chain"
      title="Rede de Supermercados"
      description="Faça sua compra online, escolha receber na sua casa ou retirar sua compra na loja"
      chainResponse={chainResponse}
    >
      <ChainProvider chainResponse={chainResponse}>
        <AddressProvider chainResponse={chainResponse}>
          <ClosestMerchant />
          <DeliveryAddress />
        </AddressProvider>
      </ChainProvider>
    </Page>
  );
};

type ServerProps = {
  chainResponse?: ChainResponse;
  serverError?: string;
};

export const getServerSideProps: GetServerSideProps<ServerProps> = async (
  context,
) => {
  const logger = new SsrLogger();
  const { chain } = context.query;
  const { req, res } = context;

  try {
    let chainResponse: ChainResponse;
    const origin = req.headers["x-ifood-forwarded-host"] as string;

    if (origin) {
      chainResponse = await Chain.getChainByHost(origin);
    } else {
      chainResponse = await Chain.getChainBySlug(chain as string);
    }

    if (chainResponse.redirectMerchant) {
      res.setHeader(
        "Location",
        `/${chainResponse.slug}/${chainResponse.redirectMerchant}`,
      );
      res.statusCode = 302;
      res.end();
    }

    return { props: { chainResponse } };
  } catch (e) {
    const error = e as AxiosError;

    if (error.response?.status === 404) {
      logger.info({ message: error.message });
      return { props: {}, notFound: true };
    }

    logger.warn({ error });

    return {
      props: { serverError: JSON.stringify(error) },
    };
  }
};

export default ChainPage;
