import { maxWidth } from "@app/utils/viewport";
import {
  Flex,
  Text as PomodoroText,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const MerchantsContainer = styled(Flex)`
  margin: 0 auto;
  width: -webkit-fill-available;
  max-width: ${maxWidth};
  flex-direction: column;
  gap: ${({ theme }) => theme.space.largest};
  padding: ${({ theme }) => `${theme.space.largest} ${theme.space.loose}`};

  @media (${bellowDesktop}) {
    gap: ${({ theme }) => theme.space.large};
    padding: ${({ theme }) => `${theme.space.larger} ${theme.space.regular}`};
  }
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const FilterOption = styled.button<{ active: boolean }>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.fontSizes.s};
  border-radius: ${({ theme }) => theme.space.large};
  padding: ${({ theme }) => `${theme.space.smaller} 14px`};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary : theme.colors.white};
  border: solid 1px
    ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.gray};
  color: ${({ theme, active }) =>
    active ? theme.colors.white : theme.colors.black};

  &:hover {
    background-color: ${({ theme, active }) =>
      active ? theme.colors.primary : theme.colors.grayLight};
  }
`;

export const BestOptionArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.large};

  > a {
    width: calc(25% - 52px);
    min-width: 250px;
  }

  @media (${bellowDesktop}) {
    > a {
      width: 100%;
      min-width: inherit;
    }
  }

  iframe {
    border: solid 1px ${({ theme }) => theme.colors.gray};
    border-radius: 16px;
    min-height: 200px;
    flex: auto;

    @media (${bellowDesktop}) {
      margin-bottom: ${({ theme }) => theme.space.regular};
    }
  }
`;

export const MeetOurStores = styled(PomodoroText)`
  font-size: ${({ theme }) => theme.fontSizes.l};

  @media (${bellowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.m};
    font-weight: 500;
    display: none;
  }
`;

export const MerchantGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  column-gap: ${({ theme }) => theme.space.large};
  row-gap: ${({ theme }) => theme.space.largest};

  @media (${bellowDesktop}) {
    gap: ${({ theme }) => theme.space.large};
  }
`;

export const InvertOnMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.largest};

  @media (${bellowDesktop}) {
    gap: ${({ theme }) => theme.space.large};
    flex-direction: column-reverse;
  }
`;

export const LoadingWrapper = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`;
