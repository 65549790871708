import {
  Flex,
  ImageWithFallback,
  Text as PomodoroText,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const MerchantCard = styled.a`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.regular};
  gap: ${({ theme }) => theme.space.regular};
  border-radius: ${({ theme }) => theme.space.regular};
  border: solid 1px ${({ theme }) => theme.colors.gray};
  text-decoration: inherit;

  &:hover {
    background-color: #f7f7f7;
  }
`;

export const CardRow = styled(Flex)`
  align-items: center;
  gap: ${({ theme }) => theme.space.regular};
`;

export const CardLogo = styled(ImageWithFallback)`
  border-radius: ${({ theme }) => theme.radii.l};
`;

export const CardTitle = styled(PomodoroText)`
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizes.txl};
  width: calc(100% - 64px);
`;

export const CardInfo = styled(PomodoroText)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.smaller};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: ${({ theme }) => theme.fontSizes.s};
  svg {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const BestOptionTag = styled(PomodoroText)`
  display: flex;
  width: fit-content;
  padding: 0 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.positive};
  border-radius: ${({ theme }) => theme.space.small};
  line-height: ${({ theme }) => theme.space.large};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
