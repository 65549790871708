import { Chain } from "@whitelabel-webapp/chain/shared/models";
import { Location } from "@whitelabel-webapp/shared/design-system";

import * as S from "./styles";
import { CHAIN_LOGO_URL } from "@whitelabel-webapp/shared/constants";
import { usePosition } from "use-position";
import {
  AutocompleteAddress,
  Coordinate,
} from "@whitelabel-webapp/address/shared/models";
import { Search } from "modules/address/delivery-address/src/Search";
import { useMemo } from "react";
import { MerchantsList } from "../MerchantsList";
import { ChainFooter } from "../ChainFooter";

type ClosestMerchantPageProps = {
  chain: Chain;
  onGeolocationUsage?: () => void;
  onAutocompleteAddress: (address: AutocompleteAddress) => void;
  hasGeolocationReady: boolean;
};

export const ClosestMerchantPage: React.VFC<ClosestMerchantPageProps> = ({
  chain,
  onGeolocationUsage,
  onAutocompleteAddress,
  hasGeolocationReady,
}) => {
  const logoUrl = `${CHAIN_LOGO_URL}/${chain.logoUrl}`;
  const { latitude, longitude } = usePosition(true);

  const position = useMemo(() => {
    if (!latitude || !longitude) return;
    return new Coordinate(latitude, longitude);
  }, [latitude, longitude]);

  return (
    <S.Wrapper>
      <S.ChainHeader>
        <S.Logo
          src={logoUrl}
          fallbackSrc="/images/chain/logo_fallback.png"
          objectFit="contain"
          layout="fixed"
          width={80}
          height={80}
        />

        <S.Details>
          <S.Title forwardedAs="h1">
            {`Faça sua compra online no ${
              chain.name.toLowerCase().includes("supermercado")
                ? chain.name
                : `Supermercado ${chain.name}`
            }`}
          </S.Title>
          <S.Description>
            Escolha receber na sua casa ou retirar sua compra na loja
          </S.Description>
        </S.Details>

        <S.AddressArea>
          <S.SearchArea>
            <Search
              position={position}
              placeholder="Digite seu endereço"
              onAutocompleteAddress={onAutocompleteAddress}
            />
          </S.SearchArea>

          <S.FindLocation
            onClick={onGeolocationUsage}
            disabled={!hasGeolocationReady}
            variant="tertiary"
          >
            <S.Icon component={Location} size="xs" />
            Usar minha localização
          </S.FindLocation>
        </S.AddressArea>
      </S.ChainHeader>

      <MerchantsList chain={chain} />
      <ChainFooter chain={chain} />
    </S.Wrapper>
  );
};
