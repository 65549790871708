import { useAddress } from "@whitelabel-webapp/address/shared/address-store";
import {
  AutocompleteAddress,
  AutocompleteAddresses,
} from "@whitelabel-webapp/address/shared/models";
import { chainAboyeur } from "@whitelabel-webapp/chain/config";
import { useChain } from "@whitelabel-webapp/chain/shared/chain-store";
import { useMemo, useState } from "react";
import { usePosition } from "use-position";
import { ClosestMerchantPage } from "../ClosestMerchantPage";
import { GeolocationPermissionError } from "../GeolocationPermissionError";

type GeolocationStatus = "IDLE" | "SEARCHING_MERCHANTS" | "NOT_FOUND" | "ERROR";
type UsePosition = {
  latitude?: number;
  longitude?: number;
  error?: string;
};

export const ClosestMerchant: React.VFC = () => {
  const { chain } = useChain();
  const { openAddress } = useAddress();
  const { error, latitude, longitude } = usePosition(true) as UsePosition;
  const [geolocationStatus, setGeolocationStatus] =
    useState<GeolocationStatus>("IDLE");

  const hasGeolocationReady = useMemo<boolean>(() => {
    if (!latitude && !longitude && !error) return false;
    return true;
  }, [latitude, longitude, error]);

  function onAutocompleteAddress(address: AutocompleteAddress) {
    openAddress("CHAIN", {
      defaultAutocompleteAddress: address,
    });
  }

  async function handleGeolocationAddress() {
    chainAboyeur.events.search.useMyLocation();
    if (!latitude && !longitude) {
      if (error) setGeolocationStatus("ERROR");
      return;
    }

    const addresses = AutocompleteAddresses.fromApi(
      await AutocompleteAddresses.getAddressesByReverseGeocode(
        latitude as number,
        longitude as number,
      ),
    );

    if (!addresses?.list) return;
    chainAboyeur.events.search.closestMerchant();

    openAddress("CHAIN", {
      defaultAutocompleteAddress: addresses.list[0],
    });
  }

  const hasErrorOnGeolocation = geolocationStatus === "ERROR";

  return (
    <>
      {hasErrorOnGeolocation && (
        <GeolocationPermissionError
          hasError={hasErrorOnGeolocation}
          closeDialog={() => setGeolocationStatus("IDLE")}
        />
      )}
      <ClosestMerchantPage
        chain={chain}
        onGeolocationUsage={handleGeolocationAddress}
        onAutocompleteAddress={onAutocompleteAddress}
        hasGeolocationReady={hasGeolocationReady}
      />
    </>
  );
};
