import { maxWidth } from "@app/utils/viewport";
import {
  Flex,
  Heading,
  ImageWithFallback,
  Button as PomodoroButton,
  Icon as PomodoroIcon,
  Text as PomodoroText,
} from "@whitelabel-webapp/shared/design-system";
import styled from "styled-components";

const bellowDesktop = ({ theme }: { theme: any }) =>
  `max-width: ${theme.screens.l.min}`;

export const Wrapper = styled(Flex)`
  height: 100%;
  flex: auto;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ChainHeader = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => `${theme.space.looser} ${theme.space.large}`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (${bellowDesktop}) {
    padding-bottom: ${({ theme }) => theme.space.large};
  }
`;

export const Details = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled(ImageWithFallback)`
  border-radius: ${({ theme }) => theme.space.regular};
  border: solid 1px ${({ theme }) => theme.colors.grayLight} !important;
`;

export const Title = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.xl};
  line-height: ${({ theme }) => theme.space.largest};
  color: ${({ theme }) => theme.colors.white};
  margin: 0;

  @media (${bellowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.l};
    line-height: ${({ theme }) => theme.space.large};
  }
`;

export const Description = styled(Heading)`
  line-height: normal;
  font-size: ${({ theme }) => theme.fontSizes.txl};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  margin: 0;

  @media (${bellowDesktop}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
  }
`;

export const AddressArea = styled(Flex)`
  gap: ${({ theme }) => theme.space.regular};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SearchArea = styled(Flex)`
  gap: ${({ theme }) => theme.space.large};
  justify-content: center;
  text-align: left;
  width: 100%;

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }

  > div {
    width: 618px;
    @media (${bellowDesktop}) {
      width: 100%;
    }
  }
`;

export const FindLocation = styled(PomodoroButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.s};
`;

export const Icon = styled(PomodoroIcon)`
  margin-right: ${({ theme }) => theme.space.smallest};
`;

export const Text = styled(PomodoroText)`
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  line-height: 14px;

  & a {
    color: ${({ theme }) => theme.colors.grayDarkest};
  }
`;
