import {
  Chain,
  MerchantResponse,
} from "@whitelabel-webapp/chain/shared/models";

import * as S from "./styles";
import { MerchantCard } from "../MerchantCard";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Loading } from "@ifood/pomodoro-components";

type MerchantsListProps = {
  chain: Chain;
};

export const MerchantsList: React.VFC<MerchantsListProps> = ({ chain }) => {
  const [closestMerchant, setClosestMerchant] = useState<MerchantResponse>();
  const [filteredMerchants, setFilteredMerchants] = useState(chain.merchants);
  const [filter, setFilter] = useState<"ALL" | "DELIVERY" | "TAKEOUT">("ALL");
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const hasTakeoutMerchants = chain.merchants?.some((merchant) =>
    merchant.features.includes("TAKEOUT"),
  );
  const hasDeliveryMerchants = chain.merchants?.some((merchant) =>
    merchant.features.includes("DELIVERY"),
  );
  const everyMerchantIsTakeout = chain.merchants?.every((merchant) =>
    merchant.features.includes("TAKEOUT"),
  );
  const everyMerchantIsDelivery = chain.merchants?.every((merchant) =>
    merchant.features.includes("DELIVERY"),
  );

  const showFilters =
    hasTakeoutMerchants &&
    hasDeliveryMerchants &&
    !(everyMerchantIsTakeout && everyMerchantIsDelivery);

  useEffect(() => {
    const { merchant } = router.query;
    if (merchant) {
      const newMerchant = chain.merchants?.find((item) => item.id === merchant);
      if (newMerchant) {
        setClosestMerchant(newMerchant);
      } else {
        setLoading(true);
        router.push(`${chain.slug}/${merchant}`);
      }
    }
  }, [router.query]);

  const filterDeliveryStores = () => {
    if (!chain.merchants) return;
    setFilter("DELIVERY");
    setFilteredMerchants(
      chain.merchants?.filter((merchant) =>
        merchant.features.includes("DELIVERY"),
      ),
    );
  };

  const filterTakeoutStores = () => {
    if (!chain.merchants) return;
    setFilter("TAKEOUT");
    setFilteredMerchants(
      chain.merchants?.filter((merchant) =>
        merchant.features.includes("TAKEOUT"),
      ),
    );
  };

  const filterAllStores = () => {
    setFilter("ALL");
    setFilteredMerchants(chain.merchants);
  };

  return (
    <S.MerchantsContainer>
      {(showFilters || closestMerchant) && (
        <S.InvertOnMobile>
          {showFilters && (
            <S.Filters>
              <S.FilterOption
                onClick={filterAllStores}
                active={filter == "ALL"}
              >
                Todas lojas
              </S.FilterOption>
              <S.FilterOption
                onClick={filterDeliveryStores}
                active={filter == "DELIVERY"}
              >
                Entrega
              </S.FilterOption>
              <S.FilterOption
                onClick={filterTakeoutStores}
                active={filter == "TAKEOUT"}
              >
                Retirada
              </S.FilterOption>
            </S.Filters>
          )}

          {closestMerchant && (
            <S.BestOptionArea>
              <MerchantCard
                chain={chain}
                bestOption={true}
                merchant={closestMerchant}
                onRedirect={() => setLoading(true)}
              />
              <iframe
                title="merchantmap"
                src={`https://www.google.com/maps?q=${closestMerchant.latitude},${closestMerchant.longitude}&z=17&output=embed`}
                loading="lazy"
              ></iframe>
            </S.BestOptionArea>
          )}
        </S.InvertOnMobile>
      )}

      {Boolean(chain.merchants?.length) && (
        <S.MeetOurStores>Conheça nossas lojas</S.MeetOurStores>
      )}

      <S.MerchantGrid>
        {filteredMerchants?.map((merchant) => (
          <MerchantCard
            key={merchant.id}
            chain={chain}
            merchant={merchant}
            onRedirect={() => setLoading(true)}
          />
        ))}
      </S.MerchantGrid>

      {loading && (
        <S.LoadingWrapper>
          <Loading color="primary" variant="large" />
        </S.LoadingWrapper>
      )}
    </S.MerchantsContainer>
  );
};
